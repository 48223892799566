<template>
  <div class="ip-network-details">
    <v-row>
      <v-col
        cols="12"
        md="10"
      >
        <breadcrumb :items="breadcrumbs" />
      </v-col>
      <v-col
        cols="12"
        md="2"
        class="hidden-sm-and-down"
      >
        <v-btn
          class="go-back-button"
          @click="$router.go(-1)"
        >
          <v-icon class="mr-1">
            {{ icons.mdiArrowLeft }}
          </v-icon>
          Go back
        </v-btn>
      </v-col>
    </v-row>
    <v-card>
      <v-card-title>
        <v-row>
          <v-col
            class="statistic"
            cols="6"
            md="3"
          >
            Total IPs: <span class="font-weight-regular">{{ totalAssignableAddresses }}</span>
          </v-col>
          <v-col
            class="statistic"
            cols="6"
            md="3"
          >
            Used IPs: <span class="font-weight-regular">{{ usedAddresses }} ({{ usedAddressesPercentage }}%)</span>
          </v-col>
          <v-col
            class="statistic"
            cols="6"
            md="3"
          >
            Free IPs: <span class="font-weight-regular">{{ freeAddresses }} ({{ freeAddressesPercentage }}%)</span>
          </v-col>
          <v-col
            class="statistic"
            cols="6"
            md="3"
          >
            <v-progress-linear
              :value="usedAddressesPercentage"
              height="11"
              rounded
            ></v-progress-linear>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-title>
        <v-row>
          <v-col
            cols="12"
            md="4"
          >
            <refresh-button
              :loading="isLoading"
              @click="fetchIpNetworkDetails()"
            />
          </v-col>
          <v-col
            cols="12"
            md="6"
            class="text-center"
          >
            <search-input
              v-model="search"
              :filters="searchFilters"
              :active-filter="searchColumn"
              @filter-change="onSearchFilterChange"
            />
          </v-col>
        </v-row>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="filteredItems"
        :options.sync="options"
        :loading="isLoading"
      >
        <template v-slot:item.assigned="{ item }">
          {{ item.assigned ? 'Yes' : 'No' }}
        </template>
        <template v-slot:item.customer="{ item }">
          <router-link
            v-if="item.customer"
            :to="{ name: 'customers.show', params: { id: item.customer.user.id } }"
            class="no-decoration"
          >
            {{ item.customer.user.name }}
          </router-link>
          <span v-else>
            {{ item.hostname ? item.hostname : '---' }}
          </span>
        </template>
        <template v-slot:item.host_category="{ item }">
          <chip-select
            :id="item.id"
            :items="ipHostCategories"
            :selected="item.host_category"
            :disabled="item.host_category === 'None' || item.host_category === 'Customer'"
            @change="onHostCategoryChange"
          />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios'
import {
  mdiArrowLeft,
} from '@mdi/js'
import BreadCrumb from '@/components/partials/BreadCrumb.vue'
import RefreshButton from '@/components/partials/RefreshButton.vue'
import SearchInput from '@/components/partials/SearchInput.vue'
import ChipSelect from '@/components/partials/ChipSelect.vue'

export default {
  components: {
    breadcrumb: BreadCrumb,
    RefreshButton,
    SearchInput,
    ChipSelect,
  },
  data() {
    return {
      ipNetworkId: this.$route.params.id,
      breadcrumbs: [
        { text: 'Dashboard', disabled: false, to: { name: 'dashboard' } },
        { text: 'Networking', disabled: true },
        { text: 'IP Networks', disabled: false, to: { name: 'ip-networks' } },
        { text: this.$route.params.id, disabled: true },
      ],
      ipNetwork: {},
      isLoading: true,
      isIpHostCategoriesLoading: false,
      ipHostCategories: [],
      icons: {
        mdiArrowLeft,
      },
      search: '',
      options: {
        sortBy: ['assigned'],
        sortDesc: [true],
        page: 1,
        pageCount: 1,
        itemsPerPage: 20,
        totalItems: 0,
      },
      searchColumn: 'ip_address',
      searchFilters: [
        { text: 'Ip Address', value: 'ip_address' },
        { text: 'Customer', value: 'customer.user.name' },
        { text: 'Mac Address', value: 'mac_address' },
      ],
    }
  },
  computed: {
    headers() {
      return [
        { text: 'IP Address', value: 'ip_address' },
        { text: 'Assigned', value: 'assigned' },
        { text: 'Assigned to', value: 'customer' },
        { text: 'Host category', value: 'host_category' },
        { text: 'MAC Address', value: 'mac_address' },
      ]
    },
    filteredItems() {
      if (this.search.trim() === '') return this.ipNetwork.ip_assignments

      const { searchColumn } = this
      const search = this.search.toLowerCase()

      return this.ipNetwork.ip_assignments.filter(item => {
        // Handle nested property, like 'customer.user.name'
        let value = item
        for (const prop of searchColumn.split('.')) {
          value = value && value[prop]
        }

        // Return items that match the search query
        return value && value.toString().toLowerCase().includes(search)
      })
    },
    totalAssignableAddresses() {
      return this.ipNetwork.total_assignable_addresses
    },
    usedAddresses() {
      return this.ipNetwork.used_addresses
    },
    freeAddresses() {
      return this.totalAssignableAddresses - this.usedAddresses
    },
    usedAddressesPercentage() {
      // Calculate the percentage
      const value = (this.usedAddresses / this.totalAssignableAddresses) * 100

      // Format it as a number with 2 decimal places
      let result = value.toFixed(2)

      // Remove trailing zeros after the decimal point and the point itself if it's a whole number
      if (result.indexOf('.00') > -1) {
        result = result.substring(0, result.indexOf('.00'))
      }

      return result
    },
    freeAddressesPercentage() {
      // Calculate the remaining percentage
      const value = 100 - this.usedAddressesPercentage

      // Format it as a number with 2 decimal places
      let result = value.toFixed(2)

      // Remove trailing zeros after the decimal point and the point itself if it's a whole number
      if (result.indexOf('.00') > -1) {
        result = result.substring(0, result.indexOf('.00'))
      }

      return result
    },

  },
  mounted() {
    this.fetchIpNetworkDetails()
    this.fetchIpHostCategories()
  },
  methods: {
    fetchIpNetworkDetails() {
      this.isLoading = true

      axios
        .get(`ip-networks/${this.ipNetworkId}`)
        .then(response => {
          this.ipNetwork = response.data
          this.breadcrumbs.pop()
          this.breadcrumbs.push({ text: `${this.ipNetwork.network_address}/${this.ipNetwork.prefix_length}`, disabled: true })
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    fetchIpHostCategories() {
      this.isIpHostCategoriesLoading = true

      axios
        .get('ip-host-categories')
        .then(response => {
          this.ipHostCategories = response.data
        })
        .catch(error => {
          console.error(error)
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    onHostCategoryChange({ id, value, index }) {
      // Find the item in ipNetwork.ip_assignments with the matching id
      const itemToUpdate = this.ipNetwork.ip_assignments.find(item => item.id === id)

      // Store the original value before updating it
      const originalValue = itemToUpdate ? itemToUpdate.host_category : null

      // Update the host_category value of the found item
      if (itemToUpdate) {
        itemToUpdate.host_category = value
      }

      // Make the HTTP PUT request to update the data on the server
      axios
        .put(`ip-assignments/${id}`, { host_category: index })
        .catch(error => {
          console.error(error)

          // If there's an error, revert the change in the local data
          if (itemToUpdate) {
            itemToUpdate.host_category = originalValue
          }

          // Provide feedback to the user
          this.$toast.error('Failed to update the host category. Please try again.')
        })
    },
    onSearchFilterChange(filter) {
      this.searchColumn = filter
    },
  },
}
</script>

<style scoped>
.no-decoration {
  text-decoration: none;
}
.statistic {
  font-size: 14px;
}
</style>
