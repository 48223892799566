<template>
  <v-menu
    top
    :close-on-click="closeOnClick"
    :disabled="disabled"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        class="mr-2"
        v-bind="attrs"
        v-on="on"
      >
        <!-- Handle both object and string types for the selected item -->
        <span v-if="selected">{{ getItemName(selected) }}</span>
        <v-icon
          v-if="!disabled"
          size="22"
          class="ml-1"
        >
          {{ icons.mdiMenuDown }}
        </v-icon>
      </v-chip>
    </template>

    <v-list>
      <v-list-item-group
        v-model="selectedItem"
        color="primary"
        @change="updateSelected"
      >
        <v-list-item
          v-for="(item, index) in items"
          :key="index"
        >
          <v-list-item-content>
            <!-- Handle both object and string types for items -->
            <v-list-item-title>{{ getItemName(item) }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list-item-group>
    </v-list>
  </v-menu>
</template>

<script>

import { mdiMenuDown } from '@mdi/js'

export default {
  props: {
    id: {
      type: String,
      default: 'select',
    },
    items: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: [Object, String],
      default: () => ({}),
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    closeOnClick: true,
    selectedItem: null,
    icons: {
      mdiMenuDown,
    },
  }),
  methods: {
    // New method to handle both object and string types for items
    getItemName(item) {
      return typeof item === 'object' ? item.name : item
    },
    updateSelected() {
      this.$emit('change', { value: this.items[this.selectedItem], id: this.id, index: this.selectedItem })
    },
  },
}
</script>
